import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { withStyles } from '@mui/styles';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { buildUrlWithLocaleAndPath } from '../../../../infrastructure/utilities/deriveUrlFromLocale';
import { withErrorBoundary } from '../../../containers/HOC/ErrorBoundary';

const styles = {
    card: {
        maxWidth: 345,
        minHeight: 365,
    },
    media: {
        objectFit: 'cover',
    },
};

export const TopicCard = (props) =>
{
    const {
        classes,
        content,
        media,
        style,
        locale,
    } = props;

    return (
        <Card style={style || { margin: '1rem', maxWidth: '23rem' }} raised={true} className={classes.card}>
            <CardMedia
                component="img"
                alt={media.alt || null}
                className={classes.media || null}
                height={media.height || '140'}
                image={media.src}
                title={media.title}
            />
            <CardContent>
                <Typography style={{ fontWeight: '300', fontFamily: 'Lato, sans-serif' }} gutterBottom={true} variant="h5" component="h2">
                    {content.disableTranslation ? content.titleId : <FormattedMessage id={content.titleId} />}
                </Typography>
                <Typography style={{ height: '5.6rem', fontSize: '.9rem' }} component="p">
                    {content.disableTranslation ? content.descriptionId : <FormattedMessage id={content.descriptionId} />}
                </Typography>
            </CardContent>
            <CardActions>
                <Link className="undecorate-link" to={buildUrlWithLocaleAndPath(locale, content.urlPath)}>
                    <Button variant="contained" size="small" style={{
                        marginLeft: '.4rem',
                    }}>
                        {content.btnTxt || <FormattedMessage id="view" />}
                    </Button>
                </Link>
            </CardActions>
        </Card>
    );
};

TopicCard.propTypes = {
    classes: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    media: PropTypes.object.isRequired,
    style: PropTypes.object,
    locale: PropTypes.string,
};

export default withErrorBoundary(withStyles(styles)(TopicCard));
