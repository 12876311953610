import React from 'react';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
import { CardMedia } from '@mui/material';

import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'gatsby';
import { buildUrlWithLocaleAndPath } from '../../../../infrastructure/utilities/deriveUrlFromLocale';
import determineImgSrcFromList from '../../../../infrastructure/utilities/helperfunctions/determineImgSrcFromList';
import { withErrorBoundary } from '../../../containers/HOC/ErrorBoundary';
import AnchorLink from '../../../atoms/AnchorLink';
import TagsWrapper from '../../Tags/TagsWrapper';
import images from '../../../../domain/Publication/Fixture/publicationCardImgUrls';
import { Schedule } from '@mui/icons-material';

const styles = {
    card: {
        maxWidth: 395,
        minHeight: 350,
    },
    media: {
        objectFit: 'cover',
    },
};

class PublicationCard extends React.Component
{
    constructor(props)
    {
        super(props);
        this.imgUrls = images;
    }
    shouldComponentUpdate(prevProps)
    {
        if (this.props._id === prevProps._id)
        {
            return false;
        }

        return true;
    }
  clipLongText = (text, limit) =>
  {
      if (!text)
      {
          return null;
      }
      if (!limit || typeof limit !== 'number')
      {
          return null;
      }
      const trimmedText = text.trim();
      if (trimmedText.length >= limit)
      {
          return trimmedText.substring(0, limit) + '...';
      }

      return trimmedText;
  };

  determineMediaLabel()
  {
      const { audioSrc, videoSrc } = this.props;
      if (videoSrc)
      {
          return 'video';
      }
      if (audioSrc)
      {
          return 'audio';
      }

      return 'article';
  }

  render()
  {
      const {
          classes,
          style,
          locale,
          slug,
          linkToPublisherOrganizationSite,
          publishingOrganizationName,
          title,
          publishToGlaubensfutterDate,
          _id,
          image,
          estimatedReadingTime,
      } = this.props;

      let organizationLinkContent = null;
      if (linkToPublisherOrganizationSite && publishingOrganizationName)
      {
          organizationLinkContent = (
              <div style={{ marginTop: '5px' }}>
                  <AnchorLink
                      className="publications-author-organization-chip"
                      title={publishingOrganizationName}
                      href={linkToPublisherOrganizationSite}
                      target="_blank"
                  >
                      {this.clipLongText(publishingOrganizationName, 35)}
                  </AnchorLink>
              </div>
          );
      }

      const titleTypographyProps = {
          style: { color: 'white', fontWeight: '500', fontSize: '1.35rem' },
          variant: 'h5',
          component: 'h2',
      };
      const titleContent = (
          <Typography title={title} {...titleTypographyProps}>
              {this.clipLongText(title, 100)}
          </Typography>
      );

      return (
          <Card style={{ width: '23.5rem', margin: '1rem', ...style }} raised={true} className={classes.card}>
              <div style={{ minHeight: '350px' }}>
                  <CardMedia image={image ?? determineImgSrcFromList(title, this.imgUrls)} style={{ height: '160px', backgroundColor: '#449494' }}>
                      <div className="publications-card-img-overlay">
                          <CardContent>
                              <Link className="undecorate-link" to={buildUrlWithLocaleAndPath(locale, ('publikationen/view/' + slug + '/' + _id))}>
                                  {titleContent}
                              </Link>
                          </CardContent>
                      </div>
                  </CardMedia>
                  <Divider variant="middle"/>
                  <CardContent>
                      {organizationLinkContent}
                      <TagsWrapper
                          tags={this.props.tags}
                          locale={this.props.locale}
                      />
                  </CardContent>
              </div>
              {/* Refactor into smaller component */}
              <div id="articleMetaInfo" style={{ margin: '1rem', fontSize: '13.8px', fontWeight: '300', display: 'flex', color: 'black' }}>
                  <div>
                      <FormattedDate
                          value={publishToGlaubensfutterDate}
                          year="numeric"
                          month="long"
                          day="2-digit"
                      />
                  </div>
                  <div style={{ marginLeft: '.6rem', marginRight: '.6rem' }}>|</div>
                  <div>
                      <FormattedMessage id={this.determineMediaLabel()}/>
                  </div>
                  {
                      estimatedReadingTime && (
                          <>
                              <span style={{ marginLeft: '.6rem', marginRight: '.6rem' }}>|</span>
                              <Schedule style={{ color: '#545454', fontSize: '20px', marginRight: '3px' }} /> <span style={{ marginLeft: '3px' }}>{estimatedReadingTime} Min.</span>
                          </>
                      )
                  }
              </div>
              <Divider variant="fullWidth" light={true}/>
              <CardContent>
                  <Link className="undecorate-link" to={buildUrlWithLocaleAndPath(locale, ('publikationen/view/' + slug + '/' + _id))}>
                      <Button variant="contained" size="small" color="primary" style={{
                          color: 'white',
                      }}>
                          <FormattedMessage id="view" />
                      </Button>
                  </Link>
              </CardContent>
          </Card>
      );
  }
}

PublicationCard.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: PropTypes.object,
    style: PropTypes.object,
    locale: PropTypes.string,
    slug: PropTypes.string,
    linkToPublisherOrganizationSite: PropTypes.string,
    publishingOrganizationName: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    publishToGlaubensfutterDate: PropTypes.number,
    tags: PropTypes.array,
    _id: PropTypes.string,
    audioSrc: PropTypes.string,
    videoSrc: PropTypes.string,
    image: PropTypes.string,
    estimatedReadingTime: PropTypes.number,
};

export default withErrorBoundary(withStyles(styles)(PublicationCard));
