import React from 'react';
import PropTypes from 'prop-types';

import TopicCard from '../Cards/TopicCard';
import PublicationCard from '../Cards/PublicationCard';
import { withErrorBoundary } from '../../containers/HOC/ErrorBoundary';

class CardList extends React.Component
{
    render()
    {
        const { cardListConfig, locale, cardType } = this.props;
        if (!cardType)
        {
            throw new Error('No cardType provided');
        }

        if (!cardListConfig)
        {
            throw new Error('No cardListConfig Provided to Cardlist');
        }

        if (!locale)
        {
            throw new Error('No locale Provided to Cardlist');
        }

        let ActiveCard;
        if (cardType === 'publicationCard')
        {
            ActiveCard = PublicationCard;
        }

        if (cardType === 'topicCard')
        {
            ActiveCard = TopicCard;
        }

        const cardListContent = cardListConfig.map((cardConfig, index) => (
            <ActiveCard
                key={index}
                locale={locale}
                {...cardConfig}
            />
        ));

        return (
            <React.Fragment>
                {cardListContent}
            </React.Fragment>
        );
    }
}

CardList.propTypes = {
    cardListConfig: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    cardType: PropTypes.string.isRequired,
};

const backdropJsx = (
    <div style={{ fontSize: '1.1rem', margin: '0 auto', marginTop: '3rem' }}>
    Sorry, there was an issue loading the Content. Please try reloading!
    </div>
);

export default withErrorBoundary(CardList, backdropJsx);
